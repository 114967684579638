import React from 'react'
import PropTypes from 'prop-types'

const SectionTitle = (props) => {
  const className =
    props.className ||
    `has-text-black has-text-weight-semibold is-size-5 secondary-font section-title ${
      props.highlight ? 'has-text-danger' : ''
    }`

  return <h3 className={className}>{props.children}</h3>
}

SectionTitle.propTypes = {
  children: PropTypes.string,
  className: PropTypes.string,
  highlight: PropTypes.bool,
}

export default SectionTitle
