import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import SectionTitle from '../components/general/SectionTitle'
import PageHeader from '../components/general/PageHeader'

export const BooksPageTemplate = ({
  image,
  title,
  description,
  headingClass,
  publications,
}) => {
  return (
    <div className="content">
      <PageHeader
        className={headingClass}
        backgroundImage={
          image.childImageSharp ? image.childImageSharp.fluid.src : image
        }
      >
        {title}
      </PageHeader>
      <div className="container">
        <p className="has-text-weight-bold has-text-centered">{description}</p>
      </div>
      <div className="container">
        <div className="section">
          <div className="columns">
            {publications &&
              publications.map((publication) => {
                return (
                  <section
                    key={publication.title}
                    className="notification is-primary has-text-black is-light"
                  >
                    <div className="columns is-vcentered is-multiline">
                      <div className="column is-8">
                        <a href={publication.slug}>
                          <SectionTitle>{publication.title}</SectionTitle>
                        </a>
                        <p>{publication.description}</p>
                        <div className="has-text-centered">
                          <a
                            className="button is-small  is-outlined is-primary"
                            href={publication.slug}
                          >
                            اقرأ الكتاب كاملا
                          </a>
                        </div>
                      </div>
                      <div className="column is-4">
                        <div className="has-text-centered">
                          <a href={publication.slug}>
                            <img
                              src={publication.image}
                              alt={publication.title}
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </section>
                )
              })}
          </div>
        </div>
      </div>
    </div>
  )
}

BooksPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  main: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    image1: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image2: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image3: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  testimonials: PropTypes.array,
  fullImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  pricing: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    plans: PropTypes.array,
  }),
  publications: PropTypes.array,
  headingClass: PropTypes.string,
}

const BooksPage = ({ data }) => {
  const { markdownRemark } = data

  const { frontmatter } = markdownRemark
  return (
    <Layout>
      <BooksPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        headingClass={frontmatter.headingClass}
        description={frontmatter.description}
        intro={frontmatter.intro}
        main={frontmatter.main}
        testimonials={frontmatter.testimonials}
        fullImage={frontmatter.full_image}
        pricing={frontmatter.pricing}
        publications={frontmatter.publications}
      />
    </Layout>
  )
}

BooksPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default BooksPage

export const booksPageQuery = graphql`
  query BooksPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        headingClass
        description
        publications {
          title
          description
          slug
          author
          image
        }
      }
    }
  }
`
