import React from 'react'
import PropTypes from 'prop-types'

const PageHeader = ({ children, backgroundImage, className = '' }) => {
  return (
    <div
      className={`full-width-image-container ${className} margin-top-0`}
      style={{
        backgroundImage: `url('${backgroundImage}')`,
      }}
    >
      <h1 className="page-title has-text-weight-bold is-size-2">{children}</h1>
    </div>
  )
}

PageHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  backgroundImage: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default PageHeader
